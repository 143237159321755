<template>
  <div>
    <CRow>
      <CCol sm="12">
        <Toolbars @set-action-toolbars="toolbars = $event"></Toolbars>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="4" v-if="showFilter">
        <Filtrate :typeOptions="typeOptions" @filter-product="filter = $event"></Filtrate>
      </CCol>
      <CCol :sm="getCol">
        <!-- GRID -->
        <Grid v-if="showGrid" :fields="fields" :items="items" @grid-row-selected="gridRowSelected = $event" @grid-dbclick="gridDbclick = $event"/>

        <!-- DETALHE -->
        <Detail v-else-if="!showGrid" :tracer="record">
          <template slot="default">
            <CRow>
              <CCol sm="4">
                <CInput id="product_id" readonly v-model="record.product_id">
                  <template #prepend-content
                    ><i class="fas fa-info-circle"
                  /></template>
                </CInput>
              </CCol>
            </CRow>

            <CRow>
              <CCol sm="4">
                <CInput
                  id="product_name"
                  label="Produto"
                  :readonly="readOnly"
                  v-model="record.product_name"
                />
              </CCol>
              <CCol sm="4">
                <CInput
                  id="product_presentation"
                  label="Nome Comercial"
                  :readonly="readOnly"
                  v-model="record.product_presentation"
                />
              </CCol>
               <CCol sm="4">
                <CSelect
                  id="product_type_dm"
                  label="Tipo de Produto"
                  :readonly="readOnly"
                  :options="typeOptions"
                  v-model="record.product_type_dm"
                  @update:value="record.product_type_dm = $event"
                />
              </CCol>
            </CRow>

            <CRow>              
              <CCol sm="2">
                <CInput
                  id="version_number"
                  label="Versão"
                  :readonly="readOnly"
                  v-model="record.version_number"
                />
              </CCol>

              <CCol sm="2">
                <CInput
                  id="initial_dt"
                  label="Data Lançamento"
                  type="date"
                  v-model="record.initial_dt"
                />
              </CCol>

              <CCol sm="2">
                <CInput
                  id="block_dt"
                  label="Data Bloqueio"
                  type="date"
                  readonly
                  v-model="record.block_dt"
                />
              </CCol>

              <CCol sm="2">
                <CInput
                  id="finish_dt"
                  label="Data Encerramento"
                  type="date"
                  readonly
                  v-model="record.finish_dt"
                />
              </CCol>
                            
              <CCol sm="4">
                <CSelect
                  id="status_dm"
                  label="Situação"
                  :readonly="readOnly"
                  :options="statusOptions"
                  v-model="record.status_dm"
                  @update:value="record.status_dm = $event"
                />
              </CCol>
            </CRow>

            <CRow>
              <CCol sm="12">
                <CTextarea
                  id="description"
                  :readonly="readOnly"
                  label="Descrição"
                  rows="10"
                  v-model="record.description"
                />
              </CCol>
            </CRow>
          </template>
        </Detail>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import fn from "@/js"

import Toolbars from "@/components/Toolbars";
import Grid from "@/components/FormGrid";
import Detail from "@/components/FormDetail";
import Filtrate from "./ProductFilter";

export default {
  name: "Product",
  props: {},
  components: { Toolbars, Grid, Detail, Filtrate },
  computed: {
    showFilter() {
      return this.$store.state.showFilter;
    },
    switchesIcon() {
      return this.$store.state.switchesIcon;
    },
    getCol() {
      return this.showFilter === false ? 12 : 8;
    }
  },
  data() {
    return {
      //Variaveis Fixas
      COMPONENT: {
        endPoint: "api/v01/internal/product",
        primaryKey: "product_id",
        descriptionKey: "product_name",
        config: {
          child: false,
          delete: { method: "delete", atribute: null, label: null },
          allowCrud: false,
          fields: 'default'
        },
        finds: [],
      },
      //Variaveis Mutantes
      showGrid: true,
      readOnly: false,
      toolbars: {},
      filter: {},
      record: {},
      gridRowSelected: {},
      gridDbclick: {},
      fields: [],
      items: [],
      //Variaveis Componente <Select>
      statusOptions: [],
      typeOptions: []
    }
  },
  methods: {
    async init(){
      await this.$nextTick(() => {
        fn.initializeComponent(this)
        this.typeOptions = fn.finds.findValuesDomain(3);
        this.statusOptions = fn.finds.findValuesDomain(1);
      })
      this.afterInit()
    },
    afterInit() {
      fn.getRecords(this)
    },
    setInitialValues() {
      this.record.status_dm = 1
      fn.setAction(this)
    },
  },
  watch: {
    toolbars: {
      immediate: true,
      handler(toolbars) {
        if(toolbars !== undefined && toolbars !== null && toolbars === 'add') {
          this.setInitialValues()
        } else if(toolbars !== undefined && toolbars !== null) {
          fn.setAction(this)
        }
      }
    },
    filter: {
      immediate: true,
      handler(filter) {
        if (Object.entries(filter).length !== 0) { 
          fn.getRecordsByFilters(this)    
        }
      }
    },
    gridRowSelected: {
      immediate: true,
      handler(gridRowSelected) {
        if (Object.entries(gridRowSelected).length !== 0) {           
            fn.loadRecord(this)          
        }
      }
    },
    gridDbclick: {
      immediate: true,
      handler(gridRowActionSelected) {
        if (Object.entries(gridRowActionSelected).length !== 0) {
          this.items = []
          let obj = {}
          obj.descriptionKey = `${this.gridDbclick[`${this.COMPONENT.primaryKey}`]} - ${this.gridDbclick[`${this.COMPONENT.descriptionKey}`]}`
          obj.parentName = this.$options.name
          obj.parentKey = this.COMPONENT.primaryKey
          obj.parentValue = this.gridDbclick[`${this.COMPONENT.primaryKey}`]
          this.$emit("set-hierarchy-level", obj);
        }
      }
    },
  },
  created() {    
    this.init()
  },
};
</script>
