<template>
  <div class="px-2">
    <CRow>
      <CCol sm="12">
        <Toolbars @set-action-toolbars="toolbars = $event"></Toolbars>
      </CCol>
    </CRow>
    <CRow>
      <!--<CCol sm="4" v-if="showFilter">
        <Filtrate @filter-="filter = $event"></Filtrate>
      </CCol>-->
      <CCol :sm="getCol">
        <!-- GRID -->
        <Grid v-if="showGrid" :fields="fields" :items="items" @grid-row-selected="gridRowSelected = $event" @grid-dbclick="gridDbclick = $event"/>

        <!-- DETALHE -->
        <Detail v-else-if="!showGrid" :tracer="record">
          <template slot="default">
            <CRow>
              <CCol sm="6">
                <CInput
                  id="product_parameter_tenant_id "
                  readonly
                  v-model="record.product_parameter_tenant_id ">
                  <template #prepend-content
                    ><i class="fas fa-info-circle"
                  /></template>
                </CInput>
              </CCol>
            </CRow>
            <!-- Conforme Domain -->
            <CRow v-if="parent.domain_id !== null">
              <CCol sm="12">
                <CSelect
                  id="default_value"
                  label="Valor Default"
                  readonly
                  :options="defaultOptions"
                  v-model="parent.default_value"                 
                />
              </CCol>  
              <CCol sm="12" >
                <CSelect
                  id="parameter_value"
                  label="Valor Cliente"
                  :readonly="readOnly"
                  :options="defaultOptions"
                  v-model="record.parameter_value"
                  @update:value="record.parameter_value = $event"
                />
              </CCol>
            </CRow>

            <!-- Domain nao informado -->
            <CRow v-else-if="parent.domain_id === null">
              <CCol sm="12" >
                <CInput
                  id="default_value"
                  label="Valor Default"
                  readonly
                  v-model="parent.default_value"
                />
              </CCol>
              <CCol sm="12" >
                <CInput
                  id="parameter_value"
                  label="Valor Cliente"
                  :readonly="readOnly"
                  v-model="record.parameter_value"
                />
              </CCol>
            </CRow>
          </template>
        </Detail>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import fn from "@/js"

import Toolbars from "@/components/Toolbars";
import Grid from "@/components/FormGrid";
import Detail from "@/components/FormDetail";
//import Filtrate from "./ComponentParameterTenantFilter";

export default {
  name: "ProductParameterTenant",
  props: {
    parent: Object,
  },
  components: { Toolbars, Grid, Detail, /*Filtrate*/ },
  computed: {
    showFilter() {
      return this.$store.state.showFilter;
    },
    switchesIcon() {
      return this.$store.state.switchesIcon;
    },
    getCol() {
      return this.showFilter === false ? 12 : 12;
    },
  },
  data() {
    return {
      //Variaveis Fixas
      COMPONENT: {
        endPoint: "api/v01/internal/product-parameter-tenant",
        primaryKey: "product_parameter_tenant_id",
        descriptionKey: "product_parameter_tenant_id",
        config: {
          child: true,
          parentKey: "product_parameter_id",
          parentValue: "product_parameter_id",
          parentService: "find-all-by-product-parameter",
          delete: { method: "delete", atribute: null, label: null },
          allowCrud: true,
          fields: 'default',
        },
        finds: [],
      },
      //Variaveis Mutantes
      showGrid: true,
      readOnly: false,
      toolbars: {},
      filter: {},
      record: {},
      gridRowSelected: {},
      gridDbclick: {},
      fields: [],
      items: [],
      //Variaveis Componente <Select>
      defaultOptions: [],
    };
  },
  methods: {
    async init(){
      await this.$nextTick(() => {
        fn.initializeComponent(this)
        this.typeOptions = fn.finds.findValuesDomain(6);
        this.statusOptions = fn.finds.findValuesDomain(1);
      })
      this.afterInit()
    },
    afterInit() {
      fn.getRecords(this)
    },
    setInitialValues() {
      fn.setAction(this)
    },    
    async loadDefaultOptions(payload) {
      await this.$nextTick(() => {
        this.defaultOptions = fn.finds.findValuesDomain(payload)
      }) 
    }
  },
  watch: {
    toolbars: {
      immediate: true,
      handler(toolbars) {
        if(toolbars !== null && toolbars === 'add') {
          this.setInitialValues()
        } else if(toolbars !== null) {
          fn.setAction(this)
        }
      }
    },
    filter: {
      immediate: true,
      handler(filter) {
        if (Object.entries(filter).length !== 0) { 
          fn.getRecordsByFilters(this)    
        }
      }
    },
    gridRowSelected: {
      immediate: true,
      handler(gridRowSelected) {
        if (Object.entries(gridRowSelected).length !== 0) { 
          fn.loadRecord(this)    
        }
      }
    },
    parent: {
      immediate: true,
      handler(parent) {
        if(parent!== undefined && parent.parentKey === this.COMPONENT.config.parentKey) {
          this.init()
          if(parent.domain_id !== null){
            this.loadDefaultOptions(parent.domain_id)            
          }
        } else {
          fn.reset(this)
        }
      }
    },
    defaultOptions: {
      immediate: true,
      handler(defaultOptions) {
        if(defaultOptions.length > 0) {
          defaultOptions.forEach(element => {
            element.value = String(element.value)
          });
        }
      }
    }
  }
}
</script>
